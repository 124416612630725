import React from 'react';
import {Home} from "../pages/home";
import {Vip} from "../pages/dashboard/vip";
import {HisDetail} from "../pages/bet/recDetail";
import {TrcDetail} from "../pages/bet/trcDetail";
import {FoundRec} from "../pages/funds";
import {WelfarePage} from "../pages/welfare";
import {AccountPage} from "../pages/account";
import {TeamBetPage} from "../pages/teamBet";
import {ProfitPage} from "../pages/profit";
import {FdPage} from "../pages/funds/fd";
import {SubPage} from "../pages/sub/mag";
import {DivPage} from "../pages/sub/div2";
import {MyIncPage} from "../pages/income/inc";
import {BkManage} from "../pages/bank";
import {CenterPage} from "../pages/center";
import {Personal} from "../pages/center/pers";
import {MgPage} from "../pages/msg/msg";
import {Unread} from "../pages/common/com";
import {MsgPage} from "../pages/msg";
import {Login} from "../pages/login";
const rts = {
    'login': ['', Login],
    '': ['首页', {
        exact: 1,
        component: Home
    }, 0],
    'agent/profit': ['团队盈亏', ProfitPage, 1],
    'agent/myInc': ['我的收入', MyIncPage, 1, 0],
    'sec': ['安全中心', CenterPage, 1],
    'personal': ['个人资料', Personal, 1],
    'agent/subManage': ['下级管理', SubPage, 1],
    'agent/Contracts': ['分红管理', DivPage, 1],
    'agent/openAccount': ['开户', AccountPage, 1],
    'agent/foundDetail': ['账变明细', FdPage, 1],
    'bank': ['提款管理', BkManage, 1],
    'msg': [<>站内信<Unread/></>, {
        exact: 1,
        component: MgPage
    }, 1],
    'agent/teamBet': ['团队投注', TeamBetPage, 1],
    'msg/:id': ['', MgPage, 1],
    'agent/msg': ['团队消息', MsgPage, 1],
};
const routeMap = {};
const routes = {};
routeMap.getName = link => {
    const o = routeMap[link];
    if (o) return o;
    const ls = Object.keys(routeMap).filter(k => k.indexOf(':') > -1);
    let name = '';
    ls.find(c => {
        const keys = c.match(/:\w+/g).map(c => c.substr(1));
        const r = new RegExp(c.replace(/:\w+/g, '(\\w+)'), 'gi').exec(link);
        if (keys.length && r) {
            const n = routeMap[c];
            const params = {};
            keys.forEach((k, i) => params[k] = r[i + 1]);
            if (typeof n === 'function') {
                return name = n(params)
            }
        }
        return false
    });
    return name || link;
};
const authRoutes = [];
Object.keys(rts).forEach(o => {
    const c = rts[o];
    const au = c[2];
    routeMap[o] = c[0];
    routes[o] = c[1];
    if (au) authRoutes.push([o,c[3]])
});

export {routes, routeMap, authRoutes};
