import React, {useState, useEffect} from 'react';
import "./carousel.scss";
import {Carousel, Carousel1} from "../../components/carousel/car";
import {useQuery} from "../../hooks";
import {widthStore} from "../../store";

const bch = {};
export const Banner = widthStore(({globalStore: {ads}}, {mini, small, id=35}) => {
    const cache = bch[id] = (bch[id]||[]);
    const [data, setData] = useState(cache);
    const [get] = useQuery('ad');
    useEffect(() => {
        get({id})
    }, [id])
    useEffect(() => {
        if (ads.length && cache.length) {
            let c = 0;
            for (let i = cache.length - 1; i > -1; i--) {
                if (!ads.find(a => a.id === cache[i].id)) {
                    c = 1;
                    cache.splice(i, 1)
                }
            }
            if (c) {
                setData(cache.slice())
            }
        }
    }, [])
    const act = data.length;
    return (
        <div className={"hd-banner" + (small ? ' sm' : '') + (act && !small ? ' act' : '')}>
            {mini?<Carousel1 items={data}/>:  <Carousel dot={1} showNext={mini && data} items={data}/>}
            <div className={'hid-img'}>
                {(ads || []).map((v, i) => {
                    const {pic_url} = v;
                    return <img key={i} src={pic_url} onLoad={() => {
                        if (cache.find(a => v.id === a.id)) return;
                        cache.push(v)
                        setData(cache.slice())
                    }} alt=''/>
                })}
            </div>
        </div>
    )
});
