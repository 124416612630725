import {encrypt as enc, decrypt as dec} from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import {mode, pad} from 'crypto-js';
import {history, stores} from '../store'
import mu from '../assets/music/c.mp3'
import {dateStr} from "../components/dateRange";
import React, {useMemo, useState} from "react";
import {useEventCallback} from "rxjs-hooks";
import {map, switchMap, takeUntil, withLatestFrom} from "rxjs/operators";
import {fromEvent, merge} from "rxjs";
import superagent from "superagent";
import {LAUNCH_CONFIG} from "../api/enum";

const w = {
    get state() {
        return w.cli ? w.cli.readyState : 3
    },
    tk: '',
    t: -1,
    cli: null,
    listener: {},
    task: [],
    send: d => {
        if (w.cli) {
            if (w.state === 1) {
                w.cli.send(JSON.stringify(d));
                return w;
            }
        }
        if (w.tk) {
            w.task.push(() => w.cli.send(JSON.stringify(d)));
        }
        return w;
    },
    close() {
        w.listener = {};
        w.tk = "";
        try {
            w.cli.close();
        } catch (e) {
        }
        w.cli = null;
        return w;
    },
    connect: (token) => {
        if (w.state < 2) return w;
        clearTimeout(w.t);
        w.t = -1;
        if (w.cli) {
            return w;
        }
        if (token) {
            w.tk = token;
            const {WebSocket, location: {host}} = window;
            const c = w.cli = new WebSocket('wss://' + host + '/lp/?token=' + token);
            c.onmessage = ({data: da = ""}) => {
                try {
                    const d = JSON.parse(da);
                    const {type, data, available} = d;
                    const dd = data || available
                    const ex = {...d};
                    delete ex.data;
                    delete ex.type;
                    if (type) {
                        const {listener = {}} = w;
                        const ls = listener[type] || [];
                        ls.forEach(f => f(dd, ex));
                    }
                } catch (e) {
                }
            }
            c.onclose = () => {
                w.cli = null;
                console.log('ws disconnect!');
                w.t = setTimeout(() => w.connect(token), 5e3);
            }
            c.onopen = () => {
                console.log('ws connect!');
                if (w.task) {
                    w.task.forEach(f => f())
                    w.task.length = 0;
                }
            }
            c.onerror = e => {
                console.error(e);
                w.t = setTimeout(() => w.connect(token), 5e3);
            }
        }
        return w;
    },

    subscribe: (type, fn) => {
        const {listener} = w;
        (listener[type] = (listener[type] || [])).push(fn);
        return w;
    },
    unsubscribe: (type, fn) => {
        const {listener} = w;
        const fs = listener[type] || [];
        if (fn) {
            const idx = fs.indexOf(fn);
            if (idx !== -1) fs.splice(idx, 1);
        } else fs.length = 0;
        return w;
    }
}
export const WS = w;

const AuCtx = window.AudioContext || window.webkitAudioContext;
const auCtx = AuCtx && new AuCtx();
let buf = null;
export const playMusic = () => {
    if (!auCtx) return;
    if (buf) {
        const sourceNode = auCtx.createBufferSource();
        sourceNode.buffer = buf;
        sourceNode.connect(auCtx.destination);
        sourceNode.start(0);
    } else {
        fetch(mu).then(r => r.arrayBuffer()).then(r => {
            auCtx.decodeAudioData(r, function (buffer) {
                buf = buffer;
                playMusic();
            }, function () {
            });
        })
    }
}
const issueTimeKey = '_#$5s2';
export const issuesTimer = {
    waiting(lotteryId, issue) {
        const o = {};
        try {
            Object.assign(o, JSON.parse(localStorage.getItem(issueTimeKey)))
        } catch (e) {

        }
        const [c, t] = o[lotteryId] || [];
        if (c === issue && t) {
            if (Date.now() < t * 1e3) {
                return true
            }
        }
    },
    add(lotteryId, issue, time) {
        const o = {};
        try {
            Object.assign(o, JSON.parse(localStorage.getItem(issueTimeKey)))
        } catch (e) {

        }
        o[lotteryId] = [issue, time];
        localStorage.setItem(issueTimeKey, JSON.stringify(o))
    }
}


//调试模式 明文存储 所有信息
const hs = window.location.hostname;
const debugMode = ["localhost", "sd.io", "www.bj.io", "bj.io"].indexOf(hs) !== -1 || window.name === 'debug';
// 默认密钥1
const key1 = navigator.userAgent.substr(-16);
// 默认密钥2
const key2 = navigator.userAgent.substr(0, 16);
// 加密配置
const cfg = k => ({iv: Utf8.parse(k), mode: mode.CBC, padding: pad.Pkcs7});
const scripts = {};

/**
 * 生成随机密码
 * @param {Number} [n] 生成位数 默认6
 * @param {Number[][]} [range] 密码内容范围 默认数字+大小写字母的CharCode  [[48,57],[65,90],[97,122]]
 * @return {string}
 */
export const randomPassword = (n = 6, range = [[48, 57], [65, 90], [97, 122]]) => {
    const rd = (a, b) => a + parseInt((b + 1 - a) * Math.random());
    const c = [];
    const l = range.length - 1;
    for (let i = 0; i < n; i++) {
        c.push(rd(...range[rd(0, l)]))
    }
    return c.map(d => String.fromCharCode(d)).join('');
};
/**
 * 懒加载脚本
 * @param path
 * @param cb
 */
export const loadScript = (path, cb) => {
    if (!scripts.path) {
        scripts.path = 1;
        const s = document.createElement('script');
        s.onload = cb;
        s.src = path;
        document.head.append(s);
    } else cb && cb()
};


/**
 * 日期格式化
 * @param {Date|number|string} date
 * @param {string} [format]
 * @return {string}
 */
export function convertDate(date, format = 'YYYY-MM-DD') {
    if (!date) return '---';
    date = new Date(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return format
        .replace(/Y+/, year)
        .replace(/M+/, (100 + month + '').substr(1))
        .replace(/D+/, (100 + day + '').substr(1))
        .replace(/h+/, hour)
        .replace(/m+/, minute)
        .replace(/s+/, second);
}

/**
 * 加密
 * @param str
 * @param k1 密钥A
 * @param k2 密钥B
 * @return {string}
 */
export const encrypt = (str, k1, k2) => {
    if (!k1 && !k2 && debugMode) return str;
    k1 = k1 || key1;
    k2 = k2 || key2;
    return enc(str, Utf8.parse(k1), cfg(k2)).toString();
};
/**
 * 解密
 * @param str
 * @param k1 密钥A
 * @param k2 密钥B
 * @return {string}
 */
export const decrypt = (str, k1, k2) => {
    if (!k1 && !k2 && debugMode) return str;
    k1 = k1 || key1;
    k2 = k2 || key2;
    return dec(str, Utf8.parse(k1), cfg(k2)).toString(Utf8);
};
/**
 * store 键名
 * @param key
 * @return {string}
 */
export const encStoreKey = (key) => debugMode ? key : encrypt(key).substr(-16);

/**
 * alert 配置
 * @typedef {Object} alertCfg
 * @property {boolean} [mask] 显示遮罩
 * @property {string} [title] 显示标题
 * @property {string} text 弹框内容
 * @property {string} [yes] 确认按钮文字
 * @property {string} [no] 取消按钮文字
 * @property {boolean} [maskCloseAble] 遮罩点击可否关闭
 * @property {function} [onClose] 关闭回调
 */

/**
 * tip.alert 方法
 * @typedef alertFunction
 * @type function
 * @param {(JSXElement|string|alertCfg|Number)} cfg
 */

/**
 * @see tip.alert
 * @property {alertFunction} alert
 * @see tip.popup
 * @property {alertFunction} popup
 * @see tip.popLogin
 * @property {alertFunction} popLogin
 */
export const tip = {};
const fxEr = s => {
    if (/Request has been terminated/gi.test(s)) return '网络异常，请稍后再试';
    if (/(failed to fetch|Internal Server Error|Network request failed)/gi.test(s)) s = '网络异常，请稍后再试';
    if (/Aborted$/g.test(s)) s = '';// todo：已经频闭超时错误
    if (/\d+ms exceeded/.test(s)) return '请求超时,请重试';
    return s;
}
/**
 * 错误消息提取
 * @param err
 * @param defaultText  没有匹配到任何错误字段 弹出默认消息
 * @return {string}
 */
export const fmtErr = (err, defaultText) => {
    if (typeof err === 'string') return fxEr(err);
    const {response: {body = {}} = {}, error: er0} = err || {};
    const {error, message, msg: m1, data: {error: er, message: msg, msg: m} = {}} = err.data || body || err;
    const {status_code, message: msg2} = err;
    let s = (status_code ? "[" + status_code + "]" : "") + (m || m1 || msg || message || error || er || msg2 || er0 || defaultText || "请求失败");
    return fxEr(s)
};

export const openWin = (src, target, wi = 1024) => {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth
        : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ?
        document.documentElement.clientHeight : window.screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - wi) / 2 / systemZoom + dualScreenLeft;
    const top = (height - 640) / 2 / systemZoom + dualScreenTop;
    return window.open(
        src,
        target,
        "directories=0,titlebar=0,toolbar=0,location=0,status=0," +
        `menubar=0,scrollbars=no,resizable=no,width=${wi},height=640,top=${top},left=${left}`
    );
}




export const replaceState = newState => {
    history.replace({pathname: window.location.pathname, state: newState});
};

export const limitBets = function () {
    const cache = {};

    function matrix(b, n, a) {
        const p = [];
        a = a || b.slice();
        if (n <= 1) return a;
        a.forEach(k => {
            b.forEach(v => {
                p.push([].concat(k, v))
            })
        })
        return matrix(b, n - 1, p)
    }

    function build(v) {
        const split = (/[^\d]/.exec(v) || [])[0] || '';
        const vv = split ? ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'] : ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (split) v = v.split(split);
        const l = v.length;
        const key = l + split;
        if (cache[key]) return cache[key];
        return cache[key] = matrix(vv, l).map(v => v.join(split)).join('|');
    }

    function arrCopy(a, b) {
        a.forEach((c, i) => {
            const d = b[i];
            if (Array.isArray(c)) {
                arrCopy(c, d)
            } else {
                b[i] = c;
            }
        })
    }

    return function (bs, fn) {
        const old = JSON.parse(JSON.stringify(bs));
        if (bs.length) {
            const {getNum, id, dantuo, dantuoNum: limit} = this;
            const f = arr => {
                if (!fn) {
                    return build(arr[0])
                } else {
                    arr = JSON.parse(JSON.stringify(arr));
                    arr.forEach((a, i) => {
                        if (Array.isArray(a)) arr[i] = f(a);
                        else arr[i] = 1
                    });
                    if (dantuo && limit) {
                        const b = arr[0] || [];
                        for (let i = limit, l = b.length; i < l; i++) {
                            b[i] = -1;
                        }
                    }
                }
                return arr;
            };
            if (fn) fn();
            const recover = () => {
                if (fn) arrCopy(old, bs);
                tip.alert({btnAlign: 1, text: '该玩法投注注数不能超过总注\n数的80%，您已超过 请修改，谢谢。'});
                return true;
            };
            if (/^k3|zusan|zuliu/.test(id)) return;
            if (id === 'sscyixingdingweidanfushi') {
                for (let i = 0, l = bs.length, m = bs[0].length; i < l; i++) {
                    const ro = bs[i];
                    for (let j = 0; j < m; j++) {
                        if (ro.reduce((a, b) => {
                            return a + (b === 1)
                        }, 0) > 7) {
                            return recover();
                        }
                    }
                }
            } else if (getNum(bs) * 100 / getNum(f(bs)) > 80) {
                return recover();
            }
        }
    }
}();

export const fakeNumber = (fn = a => a, c) => {
    const d = new Date();
    const today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    const now = Date.now();
    const dur = now - today;
    const fix = Math.sin(now) * 100;
    const n = fn(dur / 10 + 1000000 + fix).toFixed(2);
    const [a, b = ''] = n.split('.');
    let s = '';
    a.split('').reverse().forEach((c, i) => {
        s = c + (i % 3 === 0 ? ',' : '') + s;
    });
    if (c) return s.replace(/,$/, '');
    return (s.replace(/,$/, '.') + b).replace(/^\./, '');
};


export function bubbles(btn, click) {
    const p = btn.offsetParent;
    if (btn.ani) return;
    btn.ani = true;
    const sl = getComputedStyle(btn);
    if (sl.position !== 'absolute' && sl.position !== 'relative') {
        btn.style.position = 'relative';
    }
    let cv = btn.cv;
    const w = btn.offsetWidth;
    const h = btn.offsetHeight;
    if (!btn.cv) {
        const t = btn.offsetTop - h / 2;
        const l = btn.offsetLeft - w / 2 + 8;
        cv = btn.cv = document.createElement('canvas');
        const s = cv.style;
        s.width = w * 2 + 'px';
        s.height = h * 2 + 'px';
        s.position = 'absolute';
        s.left = l + 'px';
        s.top = t + 'px';
        s.cursor = 'point';
        s.pointerEvents = 'none';
        cv.onclick = e => {
            e.stopPropagation();
            e.preventDefault();
            click()
        };
        cv.width = w * 2;
        cv.height = h * 2;
    }
    p.appendChild(cv);
    const ctx = cv.getContext('2d');
    const bs = [];
    const max = 6;

    function create(x, i) {
        const idx = i % 2;
        bs.push({
            x: [1.5 * w, 0.5 * w][idx],
            y: [0.5 * h, 1.5 * h][idx],
            r: 5,
            s: 0,
            v: 2 + Math.random() * 2,
            q: (0.5 - Math.random()) * Math.PI,
            i: i
        });
    }

    function draw(o) {
        ctx.beginPath();
        const i = [1, -1][o.i % 2];
        const x = o.s * Math.cos(o.q);
        const y = o.s * Math.sin(o.q);
        ctx.arc(o.x + x * i, o.y - y * i, o.r, 0, Math.PI * 2);
        ctx.fillStyle = sl.backgroundColor;
        ctx.fill();
        ctx.closePath();
    }

    function next(o) {
        o.r *= 0.9;
        o.v *= 0.95;
        o.s += o.v;
        if ((o.r * o.v <= 0.08) && o.i === max) {
            ctx.clearRect(0, 0, cv.width, cv.height);
            p.removeChild(cv);
            btn.cv = null;
            btn.ani = false;
        } else draw(o);
    }

    const run = (fn, m = 0, i = 0, t = 1) => {
        if (!m || i <= m * t) {
            if (!m || i % t === 0) {
                if (fn(0, i / t)) return;
            }
            requestAnimationFrame(() => run(fn, m, i + 1, t));
        }
    };
    run(create, max, 0, 2);
    run(() => {
        if (!document.body.contains(cv)) return;
        ctx.clearRect(0, 0, cv.width, cv.height);
        bs.forEach(next);
    });
}

export const trim = a => a.replace(/^\s+|\s+$/, '');
export const val = v => v || undefined;
export const getParent = u => {
    return u[u.length - 1][1]
}

export const animChange = (cur, distance, onchange) => {
    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (callback, element) {
            return setTimeout(callback, 17);
        };
    }
    const target = cur + distance * 4;
    const step = () => {
        const dist = target - cur;
        cur = cur + dist / 5;
        onchange(cur)
        if (Math.abs(dist) > 1) {
            requestAnimationFrame(step);
        }
    };
    step();
};

export const scrollHorizontal = e => {
    const {currentTarget, deltaY} = e;
    const {scrollWidth, scrollLeft, offsetWidth} = currentTarget;
    const max = scrollWidth - offsetWidth;
    let mv = 0;
    if (max) {
        if (deltaY > 0) {
            if (max - scrollLeft > 1) {
                mv = Math.min(max, 20);
            }
        } else if (scrollLeft > 0) {
            mv = Math.max(-scrollLeft, -20);
        }
        if (mv) {
            e.preventDefault();
            e.stopPropagation();
            animChange(scrollLeft, mv,
                v => currentTarget.scrollLeft = v
            )
        }
    }
}
export const isAgentPage = () => {
    return /\/agent/.test(window.location.pathname);
}
export const getPos = (target, elm, x = 0, y = 0) => {
    if (!target) return [];
    const {offsetLeft, offsetTop, offsetParent} = target;
    x = x + offsetLeft;
    y = y + offsetTop;
    if (!offsetParent || !elm || offsetParent.contains(elm)) return [x, y];
    return getPos(offsetParent, elm, x, y)
}


export const fakeData = key => {
    const f = Math.floor(Math.random() * 1000);
    let x = (Math.random() > 0.5 ? 1 : -1);
    if (/number|fee|rate|prize|amount|turnover/i.test(key)) x = 1;
    if (/code/i.test(key)) return 'A00313';
    if (/type_id/.test(key)) return f % 10;
    if (/is_agent/.test(key)) return f % 2;
    if (/gameCategory/.test(key)) return 'RNG';
    if (/_at|time$/i.test(key)) return dateStr(new Date(2020, f % 11, f % 30), ' 12:00:00')
    if (/username/i.test(key)) return 'user_' + f;
    if (/id/i.test(key)) return f;
    if (/number|amount|prize|profit|deficit|fee|rate|turnover/i.test(key)) return (x * Math.random() * 1000).toFixed(2);
    if (/status/i.test(key)) return f % 2;
    else return f;
}
export const mixFakeDate = (cols, keys) => {
    return params => {
        const data = [];
        let i = 10;
        while (i--) {
            const o = {};
            cols.forEach(({key}) => {
                if (key) o[key] = fakeData(key)
            });
            keys.forEach(k => {
                if (!o.hasOwnProperty(k))
                    o[k] = fakeData(k)
            });
            data.push(o)
        }
        return {
            last_page: 5,
            data: data
        };
    }
}
export const uName = (role, click, name = '用户名') => ({
    name: name, cell({username, is_agent, user_id, team_number, team_numbers, id}) {
        let ico;
        const hasSub = team_number > 0 || team_numbers > 0;
        if (is_agent === undefined || !role) ico = <i className={'i-usr'}/>;
        else ico = <i className={'i-role' + (is_agent ? ' a' : '')}>{is_agent ? '代' : '玩'}</i>
        return <div className={'unm' + (click && hasSub ? ' cl' : '')}
                    onClick={e => {
                        e.stopPropagation();
                        if (click && hasSub) click(username, user_id || id)
                    }}
        >{ico}<span>{username}</span></div>
    }
})
export const colHandel = (n, cols, role, click) => {
    const o = [];
    n.forEach(k => {
        const [name, key] = cols[k];
        const b = {name}
        if (typeof key === 'function') b.cell = key;
        else b.key = key;
        o.push(b)
    })
    return [isAgentPage() && uName(role, click)].concat(o);
}


export const quickAmount = (min, max) => {
    if (!min || !max) return [];
    const close = (a = [], b) => {
        let c = 1;
        for (let i = 0, l = a.length; i < l; i++) {
            const d = a[i];
            if (b - d > 0 && b - c > b - d) c = d;
        }
        return c;
    }
    const step = Math.floor((max - min) / 16);
    const s = [1, 2, 3, 5, 10, 100, 500, 1000, 1500, 10000, 15000, 50000];
    const ss = close(s, step);
    const v = Math.max(min, ss);
    return [Math.floor(min), v + ss, v + ss * 3, v + ss * 7, +max]
}
export const numIpt = v => {
    const a = v.replace(/[^0-9.]/g, '').split('.');
    if (a.length > 1) return a[0] + '.' + a.slice(1).join('');
    return a[0]
}
export const animate = (vv = [], {
    t = 1,
    r = 0,
    done,
    next
}) => {
    let [a = 0, b = 0] = vv;
    let e = 1;
    let c = 0;
    let stop = 0;
    const v = 60 * t;
    const step = (b - a) / v;
    const run = () => {
        if (stop) return;
        a = a + e * step;
        c++;
        if (c >= v) {
            if (r) {
                e = 0 - e;
                c = 0;
            } else return done && done();
        }
        next && next(a, () => stop = 1);
        requestAnimationFrame(run)
    }
    run();
    return () => {
        stop = 1
    };
}

export const randArr = (a = [], n) => {
    const b = [];
    const c = a.slice()
    while (b.length < n && c.length > 0) {
        const idx = Math.floor(Math.random() * c.length);
        b.push(c.splice(idx, 1)[0])
    }
    return b;
}

export const scrollToTop = () => {
    let t = 0;
    let el = document.getElementById('root');
    const {onwheel, onkeydown} = el;
    el.onwheel = el.onkeydown = () => {
        t = 1;
        el.onwheel = onwheel;
        el.onkeydown = onkeydown;
    };
    const top = () => {
        if (t) return;
        const c = el.scrollTop;
        if (c > 0) {
            requestAnimationFrame(top);
            el.scrollTo(0, c - c / 8);
        } else {
            el.onWheel = onwheel;
            el.onKeyDown = onkeydown;
        }
    };
    top();
};
export const halfUnit = a => /\.0*5$/.test(a)
export const uniqArr = a => {
    const o = {};
    const b = [];
    a.forEach(v => {
        const k = JSON.stringify(v);
        if (!o[k]) {
            b.push(v);
            o[k] = 1;
        }
    });
    return b;
}
// export  const  intersection= (a=[],b=[])=>{
//   const mi0 = Math.min(...a);
//   const mi1 = Math.min(...b);
//   const ma0 = Math.max(...a);
//   const ma1 = Math.max(...b);
//   return [
//       Math.max(mi0,mi1),
//       Math.min(ma0,ma1)
//   ]
// }
/***
 * @param {number} l
 * @returns {string}
 */
export const randomStr = (l = 8) => {
    const ll = l / 2;
    let v = l;
    const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const x = [];
    while (v-- > 0) {
        x.push(a[Math.floor(Math.random() * 52)])
    }
    const r = () => x[(Math.floor(Math.random() * Date.now() * 100000)) % l] = Math.floor(Math.random() * 10);
    while (v++ < ll) r();
    return x.join('');
}
export const getApi = () => {
    const {hostname, protocol} = window.location;
    let domain = (/(\w+\.\w+)$/.exec(hostname) || [])[1];
    if (/bj.io/.test(domain)) return localStorage.api;
    if (domain) return protocol + '//newapi.' + domain;
}
export const hCurrency = v => {
    const p = [];
    let c = '';
    let d = 0;
    if (v) v.split('').forEach(a => {
        const isD = /\d/.test(a);
        if ((isD && d) || (!isD && !d)) c += a;
        else {
            p.push([c, d])
            c = a;
            d = isD;
        }
    });
    if (c) p.push([c, d]);

    return <>{
        p.map(([v, d], i) => {
            return d ? <b key={i}>{v}</b> : v
        })
    }</>
}
export const getRegCode = () => {
    const u = window.location.hostname;
    const a = (/^(\w{4,8})\.\w+\.\w+$/.exec(u) || [])[1];
    if (a) return a;
}
export const jumEncode = a => a.split("").map(a => (a.charCodeAt(0) + 10).toString(36)).join("");

export const sortArr = (ar = [], srt = [], f = a => a) => {
    ar.slice().sort((a, b) => (srt.indexOf(f(a)) > srt.indexOf(f(b))) ? 1 : -1)
    return ar
}


const replaceDomain = (a, b) => {
    if (!b || !a || /npay\.theqian/.test(a)) return a;
    if (/<form/gi.test(a)) {
        return a.replace(/(action=['"]?https?:\/\/)(.*?)\//gi, '$1' + b + '/')
    }
    return a.replace(/^(https?:\/\/)(.*?)\//gi, '$1' + b + '/')
}
export const processPay = ({aBankInfo, type, redirectURL, sRedirectUrl} = {}, p = {}) => {
    const fast = /usdt/i.test(p.identifier) ? '' : window.localStorage.payUrl;
    if (aBankInfo) {
        stores.channelStore.payType = 'bank';
    } else if (type === 'html' && redirectURL) {
        const opener = window.open('about:blank');
        if (opener) opener.document.write(replaceDomain(redirectURL, fast));
    } else {
        if (sRedirectUrl) {
            const o = window.open('about:blank');
            o.location.href = replaceDomain(sRedirectUrl, fast);
        }
    }
}


export const useMove = (trigger, limit = 100) => {
    const prevPos = useMemo(() => [0, 0, 0, 0], [])
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [onMouseDown] = useEventCallback(
        (event$, state$) => {
            const {offsetWidth, offsetHeight} = document.body;
            const [mx, my] = [offsetWidth - limit, offsetHeight - limit];
            return event$.pipe(
                withLatestFrom(state$),
                map(([e, prevPos]) => [e.clientX, e.clientY, prevPos]),
                switchMap(([startX, startY]) => {
                    prevPos[0] = prevPos[2];
                    prevPos[1] = prevPos[3];
                    const es = ["mouseup", "mouseout"].map(c => fromEvent(window, c));
                    const event$ = merge(...es);
                    return fromEvent(window, "mousemove").pipe(
                        map(({clientX, clientY}) => {
                            const pos = [
                                Math.min(Math.max(limit, clientX), mx) - startX + prevPos[0],
                                Math.min(Math.max(limit, clientY), my) - startY + prevPos[1]
                            ];
                            setX(pos[0]);
                            setY(pos[1]);
                            prevPos[2] = pos[0];
                            prevPos[3] = pos[1];
                            return pos;
                        }),
                        takeUntil(event$)
                    );
                })
            )
        }, [0, 0, trigger]
    );
    return useMemo(() => {
        return [onMouseDown, {transform: `translate3d(${x}px, ${y}px, 0)`}, () => {
            prevPos[0] = prevPos[1] = prevPos[2] = prevPos[3] = 0;
            setX(0);
            setY(0);
        }];
    }, [x, y])
}


const getPay = () => {
    superagent('get', getApi() + '/pay/list/domains.json').then(function ({status, body,text}) {
        try {body=JSON.parse(text)}catch (e){}
        const {domains} = body || {};
        if (status === 200 && Array.isArray(domains)) {
            let best = '';
            domains.forEach(a => {
                const m = new Image()
                m.onload = () => {
                    if (!best) {
                        best = a;
                        window.localStorage.payUrl = a;
                    }
                }
                m.src = '//' + a + '/favicon.ico?' + Math.random();
            })
        }
    }).catch(e => {
    })
    setTimeout(getPay, 1e3 * 60 * 10);
}
getPay();
// http://pay.pbn4m.xyz/favicon.ico

const _op = window.open
window.open=function (...args){
    if(/^game:\/\//i.test(args[0])){
         const {globalStore:{token}} = stores;
         if(!token)return tip.popLogin(1);
         const [type,pid] = args[0].substr(7).split('_');
         const [id, , , ptf, win] =(LAUNCH_CONFIG[type]||{})[pid]||[];
        return openWin( '/launch.html?'
            + btoa([pid, 1, id, ptf, win,
                token].join('&')), 'game');
    }else {
       return  _op.call(window,...args);
    }
}
