import React, {useEffect, useMemo, useState} from 'react';
import {Page} from "../common/page";
import {NoDate, X} from "../common/com";
import './pr.scss'
import {Tabs} from "../../components/tabs";
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import {widthStore} from "../../store";
import {PopUpWin} from "../../components/popup/popup";
import {Banner} from "../home/Banner";

const Card = ({banner, id}) => {
    const s = banner ? {
        backgroundImage: `url(${banner})`
    } : {}
    const open = () => {
        popPro.show(id)
    }
    return <div className={"pr-card " + (banner ? '' : 'no')}>
        {banner ? <div onClick={open} className={'pic'} style={s}/> : null}
    </div>
}
const Pop = ({id}) => {
    const [get, ld, ps] = useQuery('activities');
    useEffect(() => {
        if (id) get();
    }, [id])
    const {name, content, banner} = useMemo(() => {
        if (id && ps && ps.length) {
            return ps.find(a => a.id === id) || {}
        }
        return {};
    }, [id, ps])
    return ld ? <Loading>正在加载</Loading> : <>
        {banner ? <img src={banner} alt={''}/> : null}
        <h3>{name}</h3>
        <pre ref={a => {
            if (a&&content) {
                a.innerHTML = content
                    .replace(/style=".*?"/g,'')
                    .replace(/width="\d+"/g,'');
                a.scrollTo(0,0);
            }
        }}/>
    </>
}
export const popPro = {}
export const PopPromotion = () => {
    const [t, tt] = useState(0);
    return <PopUpWin
        cls={'activeWin'}
        onClose={() => {
            setTimeout(() => tt(-1), 300)
        }}
        read={({show, hide}) => {
            popPro.show = i => {
                tt(i);
                show(i)
            }
            popPro.hide = () => {
                hide()
                tt(0);
            }
        }}>
        <Pop id={t}/>
    </PopUpWin>
}

export const PromotionPage = widthStore(({
                                             globalStore: {
                                                 activeTypes
                                             }
                                         }, {match: {params: {id}}}) => {
    const [tb, tt] = useState(0)
    const [get, ld, ps] = useQuery('activities')
    const [tps] = useQuery('aType');
    useEffect(() => {
        get();
        tps();
    }, [])
    const ls = useMemo(() => {
        const p = ps || [];
        return !tb ? p : p.filter(i => {
            return +i.promo_type_id === +tb
        })
    }, [ps, tb])
    return <Page cls={'pro'}>
        <div className={'pr-hd'}>
            <Banner/>
        </div>
        <X>
            <div id={'promoList'}>
                <Tabs change={i => {
                    tt(i);
                }}>{o => <div className={'tbs'}>
                    {[
                        [0, '全部优惠'],
                    ]
                        .concat(activeTypes)
                        .map(([id, name], i) => <div {...o(id)} key={i}><i className={'z' + id}/>{name}</div>)}
                </div>}</Tabs>
                <div className={'ls'}>
                    {ls.length ? ls.map(({id, name, content, banner}) => <Card key={id} banner={banner} id={id}/>)
                        : <NoDate>暂无相关内容</NoDate>
                    }
                    <Loading act={ld}>正在加载</Loading>
                </div>
            </div>
        </X>
    </Page>
});
