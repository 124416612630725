import './t.scss'
import React, {useEffect, useMemo, useState} from 'react'
import {panel, Tabs} from "../../components/tabs";
import {MyDatePicker} from "../../components/dashboard/gameRecord/MyDatePicker";
import {Select} from "../../components/select/select";
import {change} from "../../components/pwd";
import {widthDashSheet} from "../../components/sheet";
import {colHandel, convertDate, isAgentPage, trim, val} from "../../utils";
import {useQuery} from "../../hooks";
import {PrizeNum, SelIpt} from "../common/com";
import {TrcD} from "../bet/trcDetail";
import {BetD} from "../bet/recDetail";


const PopUp = ({act, children, back}) => {
    const [h, hh] = useState(0)
    const s = {marginTop: -h - 54 + 'px'}
    return act ? <div className={'pop-up'} style={s} ref={a => {
        if (a) {
            const v = a.previousSibling.previousSibling.offsetHeight;
            if (v !== h) hh(v);
        }
    }}>
        <div className={'bn'} onClick={back}>{'<返回'}</div>
        <div className={'ctx'}>
            {children}
        </div>
    </div> : null
}


export const TeamBetPage = widthDashSheet((sheet, {betStore, thirdGameStore}) => {
    const [t0, tt0] = useState(0);
    const [v0, vv0] = useState(0);
    const [v2, vv2] = useState([]);
    const [v3, vv3] = useState(0);
    const [v4, vv4] = useState(undefined);
    const [v5, vv5] = useState('');
    const [v6, vv6] = useState('');
    const isNoLo = v0 !== 2 && v0 !== 3;
    const [type, setType] = useState('');
    const [getGames, gameLoading] = useQuery('getLottery');
    const [getTCGGames, gameTCGLoading, tcg] = useQuery('thirdLotteries');
    const [getMethod] = useQuery('gameWay');
    const cate = ['live', 'sport', '', 'lotto', 'rng', 'pvp', 'fish'][v0];
    const {availableGames, productType} = thirdGameStore;
    const types = [['', '请选择游戏厂商']]
        .concat((availableGames[cate] || [])
            .map(k => [k, productType[k]]));
    console.log(types)
    const now = Date.now();
    const [start, ss] = useState(convertDate(now - 1e3 * 3600 * 24));
    const [end, se] = useState(convertDate(now));
    const c = !isNoLo;
    const isLo2 = v0 === 3;
    const api = (c && !isLo2) ? t0 ? 'getChaseRecord' : 'getGameRecord' : 'thirdBetDetail';
    const changeTime = ({start, end}) => {
        ss(start);
        se(end);
    };
    useEffect(() => {
        if(isLo2)getTCGGames();
    }, [isLo2]);
    useEffect(() => {
        getGames();
    }, []);
    useEffect(() => {
        if (!isNoLo) {
            if (v3) getMethod({lottery_id: v3})
        }
    }, [isNoLo, v3]);
    const o = isNoLo ? {
        game_type: val(cate),
        product_id: val(type),
    } : isLo2 ? {
        game_type: val(cate),
        gameId: val(v3),
        status: v4,
    } : {
        lottery_id: val(v3),
        parent_id: '',
        status: v4,
    };
    if (isAgentPage()) {
        o.is_team = 1;
        o.username = trim(v5);
    }
    o.page_size = 10;
    o.start = start ? `${start} 00:00:00` : '';
    o.end = end ? `${end} 23:59:59` : '';
    o.is_need_sum = 1;
    if (!isNoLo) {
        if (isLo2) {
            o.numero = val(v6)
        } else if (v2[1]) {
            o[['issue', 'serial_number'][v2[0]]] = v2[1]
        }
    }
    const sta = ['待开奖', '已撤销', '未中奖', '已中奖', '已派奖', '系统撤销'];
    const sta1 = ['进行中', '已完成', '用户终止', '管理员终止', '系统终止'];
    const fx = (k) => {
        return d => {
            const {betStatus} = d;
            const v = d[k];
            if (+betStatus) return v;
            return '-'
        }
    }
    const cols = {
        0: ['奖期', 'issue'],
        0.5: ['奖期', 'numero'],
        1: ['游戏', ({lottery_id}) => {
            const name = betStore.lotteries.getNameById(lottery_id);
            return <div key={lottery_id} title={name} className="re-child">
                {name}
            </div>
        }],
        1.5: ['游戏', 'remark'],
        2: ['游戏名称', ({game_name, gameCode}) => {
            return game_name || gameCode
        }],
        3: ['玩法', 'title'],
        3.5: ['玩法', 'playName'],
        4: ['投注时间', 'betTime'],
        4.5: ['投注时间', 'bettingTime'],
        5: ['投注时间', 'bought_at'],
        6: ['投注额', 'betAmount'],
        6.5: ['投注额', 'actualBetAmount'],
        61: ['投注额', 'amount'],
        7: ['有效投注额', fx('validBetAmount')],
        8: [<div className={'stu-h'}><i/><i/><i/><i/><span>状态</span></div>, ({prize, commission_status, status}) => {
            return <div className={'stu s-' + status}>
                {+prize || sta[commission_status !== undefined ? commission_status : status]}
            </div>
        }],
        9: ['详情', (d) => {
            return <div onClick={() => setItem(d)} className={'i-dt'}>
                ...
            </div>
        }],
        10: ['游戏编号', 'betOrderNo'],
        11: [<div className={'stu-h tk'}><i/><i/><span>盈虧</span></div>, ({netPnl}) => {
            return <PrizeNum value={netPnl}/>
        }],
        12: ['下注时间', 'transactionTime'],
        115: ['厂商', 'product'],
        13: ['结算时间', 'settleTime'],
        14: ['赔率', 'odds'],
        15: ['结算金额', ({winAmount, betStatus}) => {
            if (+betStatus) return winAmount;
            return '未结算'
        }],
        16: ['下注状态', ({betStatus}) => {
            return ['-', '赢', '亏', '合', '退回', '半赢', '半亏', '提前兑现'][betStatus];
        }],

        17: ['起始奖期', 'start_issue'],
        18: ['追号进度', ({finished_issues, total_issues}) => {
            return `${finished_issues}/${total_issues}`
        }],
        19: ['总追号金额', 'amount'],
        20: ['已中奖金额', 'status'],
        20.5: ['中奖金额', 'winAmount'],
        21: ['追中即停', 'status'],
        22.5: ['下注状态', ({result}) => {
            return {
                2: '未开奖',
                4: '已中奖',
                5: '未中奖',
                6: '追中撤单',
                7: '出号放弃',
                8: '个人撤单',
                12: '空开撤单',
                14: '系统撤单',
                15: '超级撤单',
                16: '和局 (六合彩用)',
                17: '后台撤单'
            }[result] || '-'
        }],
        22: ['状态', ({commission_status, status}) => {
            return <div className={'stu s-' + status}>
                {sta1[commission_status !== undefined ? commission_status : status]}
            </div>
        }],
        23: ['比赛名称', ({eventName}) => {
            if (eventName && eventName.length > 8) {
                return <span title={eventName}>
                    {eventName.substr(0, 8) + '...'}
                </span>
            }
            return eventName
        }],
        24: ['投注内容', ({selection}) => {
            if (selection && selection.length > 8) {
                return <span title={selection}>
                    {selection.substr(0, 8) + '...'}
                </span>
            }
            return selection
        }]
    }
    let noData = ['暂无数据']
    const cfg = {
        0: [2, 10, 4, 6, 7, 11], //live
        1: [115, 23, 24, 12, 14, 6, 7, 15, 16, 11], //sport
        3: [0.5, 1.5, 3.5, 4.5, 6.5, 20.5, 22.5],//tcg
        2: t0 ? [17, 1, 3, 18, 19, 20, 21, 22, 9] : [0, 1, 3, 5, 61, 8, 9], //lott
        4: [2, 10, 4, 6, 11], //rng
        6: [2, 10, 4, 6, 11],//fish
        5: [2, 10, 4, 6, 7, 11]//pvp
    }
    if(!isAgentPage()){
        cfg[1].splice(4,0,13);
    }
    const col = colHandel(cfg[v0], cols)
    const [item, setItem] = useState(null)
    const [mx0, mMx0] = useState({})
    const [mx1, mMx1] = useState({})
    const d = [mx0, mx1][t0];
    const onChange = (id) => {
        if (t0) {
            mMx1({...mx0, [id]: {status: 2}})
        } else {
            mMx0({...mx0, [id]: {status: 1}})
        }
    }
    const gms = useMemo(() => {
        if (v0 === 2) {
            return betStore.lotteries.map(({id, name}) => [id, name])
        } else if (v0 === 3) {
            return (tcg || []).reduce((a, b) => {
                return a.concat(b.games)
            }, []).map(c => [c.gameId, c.remark])
        }
    }, [v0, tcg])
    const mixData = Object.keys(d).map(k => {
        const o = d[k];
        o.id = k;
        return [({id}) => +id === +k, o];
    })
    const act = !isNoLo && item;
    const Cpm = [BetD, TrcD][t0]
    return sheet({
        title:'团队投注',
        cls: 'tBetPage',
        tabs: ['真人', '体育/电竞', '彩票', '盘口彩票', '电子', '棋牌', '捕鱼'],
        mixData,
        tabChange: (v, q) => {
            setItem(null)
            vv0(v);
            q();
        },
        noData,
        cols: col,
        ctxChild: <PopUp act={act} back={() => setItem(null)}>
            {act ? <Cpm d={item} ls={betStore.lotteries} onChange={onChange}/> : null}
        </PopUp>,
        params: o,
        total(d) {
            return d ? d.last_page : 1
        },
        fixData(d) {
            if (d) {
                return d.data
            }
        },
        foot: d => {
            const {
                sum: {
                    profit,
                    betAmount,
                    netPnl,
                    turnover,
                    validBetAmount
                } = {}
            } = d || {};
            if (!isNoLo && t0) return ''
            const v = (...a) => {
                let o = 0;
                for (let i of a) {
                    if (i !== undefined) {
                        o = +i || o;
                        break
                    }
                }
                return o;
            }
            return <div>
                {validBetAmount !== undefined ? <>有效注额：<b>{v(validBetAmount)}</b></> : ''}
                注额：<b>{v(turnover, betAmount)}</b>
                盈亏：<b>{v(profit, netPnl)}</b>
            </div>
        },
        api,
        filterCls: 'ft-a',
        filter: q => <>
            <div className={'ft-b'}>
                <div className={'r'}>
                    {panel(!isNoLo && !isAgentPage() && !isLo2, <Tabs change={v => {
                        tt0(v);
                        setItem(null)
                    }}>
                        {
                            o => <div className={'l-tbs'}>
                                <div {...o(0)}>投注记录</div>
                                <div {...o(1)}>追号记录</div>
                            </div>
                        }
                    </Tabs>)}
                    {panel(isAgentPage(), <>
                        <input type={'text'} className={'i-usr'} placeholder={'请输入下级账号'} value={v5}
                               onChange={change(vv5)}/>
                    </>)}
                    <MyDatePicker changeTimeFn={changeTime} quick={[1,2,7,30]} noSelect={1}/>
                    {panel(!isNoLo, <>
                        {isLo2 ? <><input placeholder={'奖期'} onChange={change(vv6, trim)} value={v6}/></>
                            : <SelIpt onChange={vv2} value={v2} data={[
                                [0, '奖期', '奖期编号'],
                                [2, '订单', '订单编号']
                            ]}/>}
                        <Select onChange={vv3} value={v3}
                                loading={(isLo2 ? gameTCGLoading : gameLoading) ? '加载中' : ''}
                                items={[
                                    [0, '全部游戏']
                                ].concat(gms)}
                        />
                        <Select items={['状态'].concat([sta, sta1][+isLo2]).map((a, i) => [i ? i - 1 : undefined, a])}
                                value={v4}
                                onChange={vv4}
                        />
                    </>)}
                    {panel(isNoLo, <>
                        <Select
                            value={type}
                            onChange={setType}
                            items={types}
                        />
                    </>)}
                    <div className={'btn'} onClick={q}>查询</div>
                    {panel(isNoLo || isLo2, <p className={'inf'}>提示：投注数据每15分种更新一次。</p>)}
                </div>
            </div>
        </>
    })
});
