import React, {useState} from 'react';

import './vip.scss'
import {widthDashBoard} from "../../../components/dashboard/Dashboard";
import {DashTitle} from "../../common/com";
import {Link} from "react-router-dom";
import {panel} from "../../../components/tabs";

const C = ({lv, c, cc}) => {
    const t = [
        [0, 0],
        [3000, 500],
        [50000, 10000],
        [200000, 50000],
        [1200000, 200000],
        [3000000, 500000],
        [8000000, 1500000],
        [18000000, 3000000],
        [60000000, 10000000]
    ];
    const step = 100 / 9;
    const s = {
        transform: `translateX(${-step * c}%)`
    }
    return <div className={'vip-c-ls'}>
        <i className={'p' + (c ? '' : ' d')} onClick={() => cc(Math.max(0, c - 1))}/>
        <div className={'sc'}>
            <div className={'s'} style={s}>
                {t.map(([a, b], i) => {
                    const nx = t[i + 1];
                    const v = lv === c ? '当前等级' : c > lv ? '待升级' : '';
                    return <div key={i} className={'vip-card v-' + i}>
                        <div className={'bg'}/>
                        {v && <label>{v}</label>}
                        <div className={'ct'}>
                            <s/>
                            <div>
                                <h1>VIP{i}</h1>
                                <span>流水<b>{a.toFixed(2)}</b> / 存款<b>{b.toFixed(2)}</b></span>
                            </div>
                        </div>
                        <div className={'ro'}>
                            <span>VIP{i}</span>
                            <div/>
                            <span>VIP{Math.min(8, i + 1)}</span>
                        </div>
                        {panel(nx, () => <>
                                <p>流水{nx[0]} 存款{nx[1]} 升级至下一等级</p>
                            </>
                        )}
                    </div>
                })}
            </div>
        </div>
        <i className={'n' + (c !== 8 ? '' : ' d')} onClick={() => cc(Math.min(8, c + 1))}/>
    </div>
}
const B = ({lv, act, name, cls}) => {
    return <div className={'vip-c'}>
        <div>
            <h1>{name}</h1>
            <div/>
            <span>VIP{lv}专享</span>
        </div>
        <i className={act ? cls : ''}/>
    </div>
}

const A = widthDashBoard((_, {
    globalStore: {
        vip_level
    }
}) => {
    const [c, cc] = useState(vip_level)
    return <div className={'x-sheet vipPage'}>
        <div className={'x-st-h'}>
            <DashTitle title={'VIP中心'}/>
            <div className={'u'}>
                <Link to={'vip'}>查看VIP详情</Link>
            </div>
        </div>
        <div className={'x-ss'}>
            <div className={'x-tba'}>
                <C lv={vip_level} c={c} cc={cc}/>
                <div className={'ls'}>
                    {[
                        [1, '每月红包', 1],
                        [2, '晋级礼金', 2],
                        [3, '生日礼金 ', 3],
                        [4, '专移属活动', 4],
                        [5, '佳节礼品', 5],
                        [6, '存取款专属', 6],
                        [7, '提款次数增加', 7],
                        [8, '极速出款', 8]
                    ].map(([a, b, cu], i) =>
                        <B lv={a} act={a <= c} name={b} cls={'i-' + cu} key={i}/>)}
                </div>
            </div>
        </div>
    </div>

})

export const Vip = props => <A {...props} cls={'xt'}/>
