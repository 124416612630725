import React, {useState} from 'react';
import {widthDashSheet} from "../../components/sheet";
import './s.scss'
import {change} from "../../components/pwd";
import {fmtErr, tip, trim, uName, val} from "../../utils";
import {Select} from "../../components/select/select";
import {PupSetting} from "./p";
import {query, seqQuery} from "../../api";
import {dateStr, fixD3} from "../../components/dateRange";
import {Inf, PrizeNum, Tip} from "../common/com";


export const DivPage = widthDashSheet((sheet, {globalStore: {bonus_rate}}) => {
    const [v0, vv0] = useState('')
    const canEdit = 1;
    const [v1, vv1] = useState(0)
    const [v2, vv2] = useState(0)
    const [v3, vv3] = useState('')
    const [user, uUser] = useState([]);
    const [mixData, sMix] = useState({});
    const [ld, sLd] = useState({});
    const subClick = (u, i) => () => uUser([u, i]);
    const close = () => uUser([]);
    const now = new Date();
    const [y, m] = [now.getFullYear(), now.getMonth()]
    const rs = [1, 2, 3].map(n => {
        const mm = m - n;
        const d = new Date(y, mm, 1);
        const e = new Date(y, mm + 1, 0);
        return [mm, fixD3(d) + '-' + fixD3(e)]
    })
    const [mo, mmo] = useState(m - 1);
    const params = {
        is_need_sum: 1,
    };
    params.username = v0.toLowerCase();
    if (!v2) {
        if (v1) params.status = 2 - v1;
    } else {
        params.start = dateStr(new Date(y, mo, 1))
        params.end = dateStr(new Date(y, mo + 1, 0))
        params.status = v3;
    }
    const changeStatus = i => d => {
        const {id} = d
        const n = new Date();
        d.tab = i;
        d.updated_at =
            [n.getFullYear(), n.getMonth() + 1, n.getDate()].join('-') + ' '
            + [n.getHours(), n.getMinutes(), n.getSeconds()].join(':')
        sMix({...mixData, [id]: d})
    };
    const mix = Object.keys(mixData).map(k => [({id}, {tab} = {}) => v2 === tab && +id === +k, mixData[k]]);
    const stu0 = [
        [0, '待审核'],
        [1, '审核通过'],
        [2, '拒绝分红'],
        [3, '已派发'],
    ]
    const hasB = bonus_rate && bonus_rate.length;
    return sheet({
        mixData: mix,
        fakeKeys: ['username', 'status', 'deficit'],
        tabChange: vv2,
        api: ['getContracts', 'getCtrBonuses'][v2],
        params,
        cls: 'divPage',
        tabs: ['签约管理', '派发管理'],
        filter: q => <div className={'r'}>
            <input placeholder={'请输入下级账号'} className={'i-usr'} type={'text'} value={v0} onChange={change(vv0, trim)}/>
            {v2 ? <>
                <label>分红周期：</label><Select value={mo} onChange={mmo} items={rs}/>
                <Select value={v3} onChange={vv3} items={[
                    ['', '全部'],
                ].concat(stu0)}/>
            </> : <Select value={v1} onChange={vv1} items={[
                [0, '选择签约状态'],
                [1, '已签约'],
                [2, '未签约']
            ]}/>
            }
            <div className={'btn'} onClick={q}>查询</div>
        </div>,
        fixData(d) {
            try {
                return d.data
            } catch (e) {
            }
            return []
        },
        ctxChild: <PupSetting user={user[0]} type={user[1]} close={close} onStatusChange={changeStatus(0)}/>,
        foot: (d) => {
            const {numbers = {}} = d || {}
            return <div>
                <span>已签约：<b>{numbers.signed || 0}</b></span>
                <span>未签约：<b>{numbers.unsigned || 0}</b></span>
            </div>
        },
        cols: [
            [
                uName(undefined,undefined,'下级用户'),
                {
                    name: '签约时间', cell({updated_at, status}) {
                        return status ? updated_at : '-'
                    }
                },
                {
                    name: '状态', cell({status}) {
                        return [<span className={'gr'}>已签约</span>, '未签约'][1 - status];
                    }
                },
                {
                    name: '操作', cell(u) {
                        const {status, username, user_id, id} = u;
                        return <div className={'opt'}>
                            {hasB ? !status ? canEdit ?
                                <div className={'bn'} onClick={subClick(u, 3)}>发起签约</div> : '-' :
                                canEdit ? <>
                                    <div className={'bn'} onClick={subClick(u, 4)}>修改</div>
                                    <div className={'bn'} onClick={ld[username] ? null : () => {
                                        tip.alert({
                                            text: `确定要解除用户${username}的签约吗?`,
                                            no: true,
                                            yes: '确定',
                                            onClose(n) {
                                                if (n) {
                                                    sLd({...ld, [username]: 1});
                                                    const d = {
                                                        status: 0,
                                                        user_id,
                                                        id
                                                    };
                                                    query('getBonus', {
                                                        user_id
                                                    }, (r) => {
                                                        const b = r || [];
                                                        seqQuery({
                                                            skip: 1,
                                                            process({finish, result, failed}) {
                                                                if (finish) {
                                                                    sLd({...ld, [username]: 0});
                                                                    if (failed)
                                                                        tip.alert(
                                                                            <Tip.Err>解约未完成\n{fmtErr(result)}</Tip.Err>);
                                                                    else {
                                                                        changeStatus(0)(d)
                                                                    }
                                                                }
                                                            }
                                                        })(
                                                            ['delBonus', b.map(({id}) => ({id, user_id}))]
                                                        );
                                                    })
                                                }
                                            }
                                        })
                                    }}>{ld[username] ? '提交中..' : '解约'}</div>
                                </> : <div className={'bn'} onClick={subClick(u, 5)}>查看</div> : null}
                        </div>
                    }
                },
            ],
            [
                {name: '下級用戶', key: 'username'},
                {name: '盈亏', cell: ({profit}) => <PrizeNum simple value={profit}/>},
                {
                    name: <>厂商费<Inf>
                        <pre>由第三方游戏厂商从游戏亏损当中收取的费用：真人：10%
                            体育：17%   彩票：无 电子：12%   棋牌：10%   捕鱼：
                            10%</pre>
                    </Inf></>, key: 'fee'
                },
                {name: '上月累计盈亏', cell: ({grand_total}) => <PrizeNum simple value={grand_total}/>},
                {name: '净盈亏', cell: ({net_profit}) => <PrizeNum simple value={net_profit}/>},
                {name: '活跃会员', key: 'active_number'},
                {name: '分红比例', key: 'rate'},
                {name: '金额', key: 'bonus'},
                {
                    name: '操作', cell({status, bonus, username, id}) {
                        if (+bonus >= 0) {
                            return status === 1 ? <div className={'bn'} onClick={() => tip.alert(
                                {
                                    text: `确定要派发分红给${username}吗？`,
                                    no: true,
                                    onClose(n) {
                                        if (n) {
                                            query('setCtrBonus', {id}, () => {
                                                changeStatus(1)({id, status: 3})
                                            })
                                        }
                                    }
                                }
                            )}>派发</div> : ((stu0[status] || [])[1] || '-')
                        }
                        return '-';
                    }
                }
            ]
        ][v2]
    })
});
