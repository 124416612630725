import React, {useEffect, useMemo, useState} from 'react';
import {PopUpWin} from "../../components/popup/popup";
import {panel, Tabs} from "../../components/tabs";
import {widthStore} from '../../store'
import {Wallet} from "../dashboard/wallets/wallets";
import './p.scss'
import {Charge} from "./charge";
import {Trans} from "./trans";
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import {Empty} from "../common/com";

export const popWallet = {}
let last = Date.now()
const Wt = widthStore(({walletStore, myBankStore, globalStore: {available, user_level,is_need_bind_card}}, {onChange, act}) => {
    const [q, l, r] = useQuery('myBankCard')
    const [q1, l1, r1] = useQuery('getUsdt')
    useEffect(() => {
        const n = Date.now();
        if (!act && n - last > 3e4) {
            walletStore.query()
            last = n;
        }
    }, [last, act])

    useEffect(() => {
        if (act > 0 && act !== 1) {
            q();
            q1();
        }
    }, [act])

    const cpm = useMemo(() => {
        const {banks, usdts} = myBankStore;
        const hasBank = (banks && banks.length) || (usdts && usdts.length);
            if (act === 1)return (!is_need_bind_card||(banks && banks.length))? <Charge/>:<Empty/>;
            if (act && (r || r1) && (!l && !l1)) {
                if (!hasBank) {
                    return <Empty/>
                }
                return <Trans/>
            }
        return null;
    }, [act, r, r1, l, l1, myBankStore,is_need_bind_card])

    return <div className={'pop-wallet'}>
        <Tabs change={onChange} act={act}>{o => <div className={'ti'}>
            {user_level ? <>
                <div {...o(0)}>钱包</div>
                <div {...o(1)}>存款</div>
            </> : null}
            <div {...o(2)}>提现</div>
        </div>}</Tabs>
        {panel(act === 0, <Wallet/>)}
        {cpm}
        <Loading act={l}>正在载入</Loading>
    </div>
})
export const PopWallet = () => {
    const [t, tt] = useState(-1);
    return <PopUpWin
        onClose={()=>{
            setTimeout(()=>tt(-1),300)
        }}
        read={({show, hide}) => {
        popWallet.show = i => {
            tt(i);
            show(i)
        }
        popWallet.hide = () => {
            hide()
            tt(-1);
        }
    }}>
        <Wt onChange={tt} act={t}/>
    </PopUpWin>
};
