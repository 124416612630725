import React, {useEffect, useState} from 'react'
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import './c.scss';
import {DashTitle, Inf} from "../common/com";
import {panel} from "../../components/tabs";
import {MyDatePicker} from "../../components/dashboard/gameRecord/MyDatePicker";
import {convertDate, tip} from "../../utils";
import {Select} from "../../components/select/select";
import {dateStr, fixD3} from "../../components/dateRange";
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import {Table} from "../../components/table/table";

const label0 = (a, b) => {
    const n = new Date();
    const y = new Date(n.getFullYear(), n.getMonth(), n.getDate() - 1);
    const w = new Date(n.getFullYear(), n.getMonth(), n.getDate() - n.getDay() + 1);
    const m = new Date(n.getFullYear(), n.getMonth(), 1);
    if (b === dateStr(n)) {
        if (a === dateStr(m)) return '本月';
        if (a === dateStr(w)) return '本周';
    }
    if (a === b && a === dateStr(y)) return '昨日';

    return a + ' 至 ' + b
}

const C = ({label = '标题', name, value, cls = '', children, desc = ''}) => {
    return <div className={'crd ' + cls}>
        <h3>{label}</h3>
        <i/>
        <div>
            <label>{name}</label>
            <h1>{value || 0}</h1>
            <p>{desc}</p>
        </div>
        <span onClick={() => {
            tip.alert(children)
        }}>
            查看{name}详情
        </span>
    </div>
}
const B = ({cols = [], data = {}}) => {
    const s = [];
    let v = -1;
    cols.forEach(([n, k], i) => {
        if (i % 2 === 0) {
            v++;
            s.push([])
        }
        let vv = '';
        if (typeof k === 'function') vv = k(data || {}) || 0
        else vv = parseFloat((data || {})[k] || 0)
        s[v].push([n, vv])
    });
    return <div className={'bb'}>
        {s.map((v, i) => <div key={i}>
            {v.map(([n, u], j) => {
                return <div key={j}>
                    <label>{n}</label>
                    <span>{u}</span>
                </div>
            })}
        </div>)}
    </div>
}
const A = widthDashBoard((props, {
    globalStore: {
        bonus_rate = [],
        commission_rate = [],
        partner_bonus_setting = [],
        prize_group,
        user_level
    }
}) => {
    const isCp = user_level === 0;
    const [com, cLd, data] = useQuery('getPrize');
    const {lottery = []} = data || {};
    const [q, ld, da] = useQuery('commission');
    const hasBonus = bonus_rate.length;
    const [gBonus, bonusLd, bs] = useQuery('bonus');// 分红
    const d0 = da || {};
    const {
        total_commission = 0
    } = d0 || {};
    const {bonus} = bs || {};
    const now = new Date();
    const [start, ss] = useState(convertDate(new Date(now.getFullYear(), now.getMonth(), 1)));
    const [end, se] = useState(convertDate(now));
    const changeTime = ({start, end}) => {
        ss(start);
        se(end);
        q(param0(start, end))
    };
    const [y, m] = [now.getFullYear(), now.getMonth()]
    const rs = [0, 1, 2].map(n => {
        const mm = m - n;
        const d = new Date(y, mm, 1);
        const e = new Date(y, mm + 1, 0);
        return [mm, fixD3(d) + '-' + fixD3(e)]
    })
    const [mo, mmo] = useState(m);
    const param0 = (s = start, e = end) => ({
        start: s, end: e
    });

    const label1 = () => {
        if (mo === m) return '本月预计'
        return rs[m - mo][1];
    }
    const param1 = (m = mo) => ({
        start: dateStr(new Date(y, m, 1)),
        end: dateStr(new Date(y, m + 1, 0))
    });
    useEffect(() => {
        q(param0());
        gBonus(param1());
        com();
    }, [])
    const fx = a => d => (+d[a] || 0).toFixed(2);
    const cR = i => {
        return (commission_rate.find(({type_id}) => type_id === i) || {}).commission_rate || 0;
    }
    const bos = isCp ? partner_bonus_setting : bonus_rate.slice();
    bos.sort((a, b) => {
        return a.rate > b.rate ? 1 : -1
    });
    return <div className={'x-sheet incPage'}>
        <div className={'x-st-h'}>
            <div className={'x-st-ti'}>
                <i/>
                <DashTitle title={'我的收入'}/>
            </div>
        </div>
        <div className={'x-ss'}>
            <div className={'x-tba'}>
                <div className={'x-sc'}>
                    <div className={'r'}>
                        <MyDatePicker quick={[-30, -7, 2]} value={[new Date(start), new Date(end)]}
                                      changeTimeFn={changeTime}/>
                    </div>
                </div>
                <div className={'r'}>
                    <C value={total_commission} name={'返点'} label={label0(start, end)}>
                        <div className={'s-inf'}>
                            <div className={'r'}>
                                <div><label>体育/电竞:</label><span>{cR(2)}%</span></div>
                                <div><label>真人:</label><span>{cR(3)}%</span></div>
                            </div>
                            <div className={'r'}>
                                <div><label>老虎机:</label><span>{cR(4)}%</span></div>
                                <div><label>棋牌:</label><span>{cR(5)}%</span></div>
                            </div>
                            <div className={'r e'}>
                                <div><label>彩票:</label><span>{cR(1)}% / {prize_group}</span></div>
                                <div><label>捕鱼:</label><span>{cR(6)}%</span></div>
                            </div>
                            {lottery.map((k, i) => {
                                if (i % 2 === 0) {
                                    const [
                                        {name: n0, commission: c0} = {},
                                        {name: n1, commission: c1} = {}
                                    ] = [lottery[i], lottery[i + 1]]
                                    const f = a => (a * 100).toFixed(2)
                                    return <div key={i} className={'r'}>
                                        <div><label>{n0}:</label><span>{f(c0)}%</span></div>
                                        {n1 ? <div><label>{n1}:</label><span>{f(c1)}%</span></div> : ''}
                                    </div>
                                }
                                return null;
                            })}
                        </div>
                    </C>
                    <B data={d0} cols={[
                        ['真人', 'commission_live'],
                        ['电子', 'commission_rng'],
                        ['体育', 'commission_sport'],
                        ['棋牌', 'commission_pvp'],
                        ['彩票', 'commission_lottery'],
                        ['捕鱼', 'commission_fish'],
                    ]}/>
                </div>
                <p className={'t'}>温馨提示：彩票是实时返点，其他游戏是隔日返点。</p>
                <Loading act={ld}>加载中</Loading>
            </div>
            {panel(hasBonus, <div className={'x-tba'}>
                <div className={'x-sc'}>
                    <div className={'r'}>
                        <label>分红周期：</label><Select value={mo} onChange={v => {
                        mmo(v);
                        gBonus(param1(v))
                    }} items={rs}/>
                    </div>
                </div>
                <div className={'r'}>
                    <C cls={'gr'} value={(parseFloat(bonus) || 0).toFixed(2)} name={'分红'} label={label1()}>
                        <div className={'s-inf'}>
                            <Table
                                row={[
                                    {key: isCp ? 'active_top_agent' : 'deficit', name: isCp ? '有效总代' : '亏损'},
                                    {key: 'active_number', name: '活跃人数'},
                                    {key: 'rate', name: '比例'},
                                ]}
                                data={bos}
                            />
                        </div>
                    </C>
                    <B data={bs} cols={[
                        ['团队盈亏', fx('profit')],
                        ['净盈亏', fx('net_profit')],
                        [<>{isCp ? '有效总代' : '厂商费'}<Inf>
                            {isCp ? <pre>有效总代为当月拿到分红的总代</pre> : <pre>{`由第三方游戏厂商从游戏亏损中收取的费用：
真人：9% 体育：17% 彩票：7%
电子：13% 捕鱼：9% 棋牌：8%`}</pre>}
                        </Inf></>, fx(isCp ? 'active_top_agent' : 'fee')],
                        [<>活跃人数<Inf>
                            <pre>分红周期内，满足三天，每天有效投注大于1000，为一个有效用户</pre>
                        </Inf></>, 'active_number'],
                        [<>上月累计盈亏<Inf>
                            <pre>月度盈利累计，三个月清零。</pre>
                        </Inf></>, fx('grand_total')],
                        ['分红比例', ({bonus, rate}) => {
                            const v = parseFloat(((isCp ? bonus : rate) * 100).toFixed(1));
                            return isNaN(v) ? '' : v + '%'
                        }]
                    ]}/>
                </div>
                <Loading act={bonusLd}>加载中</Loading>
            </div>)}
        </div>
    </div>
})
export const MyIncPage = props => <A {...props} cls={'xt'}/>
