import React from 'react'
import {dateStr, XDate} from "../../dateRange";

export const MyDatePicker = ({changeTimeFn, value,quick,simple}) => {
    return <XDate
        simple={simple}
        value={value}
        quick={quick} onChange={([start, end]) => {
        changeTimeFn && changeTimeFn({
            start: dateStr(start),
            end: dateStr(end)}
            )
    }}/>
}
