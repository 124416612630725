import {WALLETS} from "../api/enum";

const now = new Date()
setInterval(function () {
    localStorage.seed = localStorage.seed || (Math.floor(
        Math.random() * 1e11 * now.getSeconds()
        + (now.getTime() - 1628672788625)
    )).toString(36)
}, 1e3)

export const px = {
    phone: '',
    get t() {
        const end = +localStorage.sms_t || 0;
        const a = Math.floor((end - Date.now()) / 1000);
        return a > 0 ? a : 0
    },
    set t(v) {
        localStorage.sms_t = v * 1000 + Date.now();
    }
}

const isWww = /^www\./.test(window.location.host)
let allowReg = +!isWww;
const {localStorage} = window;
const gal = {
    tvTk: "",
    ___: ["jumper_page", "allow_register", "domains","cs_system",
        "need_check_phone","is_need_bind_card","is_enable_alipay"],
    t: 0,
    register_at: '',
    need_check_phone:false,
    get allow_register() {
        return allowReg
    },
    set allow_register(v) {
        if (isWww)
            allowReg = +v;
        this.t++;
    },
    activeTypes: [],
    is_enable_alipay: 0,
    domains: [],
    jumper_page: 'https://btyule.gitee.io',
    usdt_withdraw_fee: 0,
    is_used_usdt: 0,
    usdt_withdraw_default_min_amount: 0,
    usdt_withdraw_default_max_amount: 0,
    usdt_rate: 0,
    is_need_bind_card: 0,
    login_ip: '-.-.-.-',
    signin_at: '',
    unread: 0,
    cap: {},
    ads: [],
    ads1: [],
    cdn_domain: "",
    lastWinNum: '',
    token: '',
    username: '',
    phone: '',
    qq: '',
    nickname: '',
    real_name: '',
    is_agent: 1,
    wechat: '',
    email: '',
    showThemeSelect: false,
    allowed_transfer: 0,
    announcement: [],
    banners: [],
    has_fund_password: 0,
    is_valid_phone: false,
    max_prize: 400000,
    max_withdraw: 5,
    today_withdraw: 0,
    vip_level: 0,
    available: '',
    withdraw_default_max_amount: '',
    withdraw_default_min_amount: '',
    max_bet_prize_group: 0,
    min_bet_prize_group: 0,
    prize_group: "1800",
    daily_salary: 0,
    user_level: 0,
    bonus_rate: [],
    withdrawable_coefficient: 0,
    commission_rate: [],
    partner_bonus_setting: [],
    is_tester: 0,
    birthday: '',
    regTime: '',
    avatar: 0,
    capLd: 0,
    capKey: '',
    get nick_name() {
        return this.nickname || this.username
    },
    get captchaCode() {
        return this.cap[this.capKey];
    },
    regCode: '',
    set captchaCode(v) {
        if (v === 0) {
            this.cap = {}
        } else if (this.capKey) {
            this.cap[this.capKey] = v;
        }
    },
    get balance() {
        return this.available || '';
        // return '9999.00';
    },
    set balance(v) {
        this.available = v;
    }
};


export const globalStore = gal;
const www = {
    name(p) {
        const stores = this.getStore();
        const {thirdGameStore: {productType}} = stores;
        const n = WALLETS[p] || productType[p] || '';
        return p === 'main' ? '主钱包' : n;
    },
    get wallets() {
        if (!this.getStore) return [];
        const stores = this.getStore();
        const {thirdGameStore} = stores;
        const {wallets} = thirdGameStore;
        return ['main'].concat(wallets).map(k => this.bls[k]).filter(a => a);
    },
    get totalBalance() {
        return Object.values(this.bls).map(a => a.balance).reduce((a, b) => (a || 0) + (b || 0), 0)
    },
    get loading() {
        return !!Object.values(this.bls).find(k => k.loading)
    },
    bls: {},
    query(...v) {
        const {query, walletStore} = this.getStore();
        const q = k => {
            const o = walletStore.bls[k];
            if (!o || o.loading) return;
            o.loading = 1;
            walletStore.bls = {...walletStore.bls};
            const isMain = k === 'main';
            query(
                isMain ? 'balance' : 'thirdBalance',
                isMain ? undefined : {product_id: k},
                (r) => {
                    const {balance, available} = r;
                    o.balance = +available || +balance;
                    o.loading = 0;
                    walletStore.bls = {...walletStore.bls}
                },
                (e) => {
                    o.loading = 0;
                    walletStore.bls = {...walletStore.bls}
                },
            );
        };
        if (v && v.length !== 0) {
            v.forEach(q)
        } else {
            Object.keys(walletStore.bls).forEach(q)
        }
    },
    load() {
        const stores = this.getStore();
        const {thirdGameStore} = stores;
        const {wallets} = thirdGameStore;
        const o = {};
        ['main'].concat(wallets).forEach(k => {
            const v = (o[k] = (this.bls || {})[k] || {
                id: k,
                loading: 0,
                balance: 0,
            });
            let ld = () => this.query(k)
            Object.defineProperty(v, 'load', {
                enumerable: true,
                configurable: true,
                get: () => ld,
                set: v => ld = v
            })
            v.name = this.name(k);
            v.loading = 0
        });
        try {
            this.bls = {...o}
        } catch (e) {
        }
        return this;
    }
};

export const walletStore = www;
