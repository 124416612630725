
export const thirdGameStore = {
    cate: {},
    productValue:{},
    productType: {},
    initProducts(v) {
        v = v || [];
        const o = {};
        const vv = {};
        const p = {};
        const w = [];
        const ks = ['lottery', 'rng', 'live', 'fish', 'pvp', 'sport']
        v.forEach(x => {
            const {id, product, status,product_type} = x;
            if (status) {
                p[id] = product;
               vv[id] = product_type;
                w.push(id);
                ks.forEach(k => {
                    if (x[k]) {
                        (o[k] = o[k] || []).push(id)
                    }
                })

            }
        });
        this.productType = p;
        this.availableGames = o;
        this.wallets = w;
        this.productValue = vv;
    },
    wallets: [],
    availableGames: {},
    getName(cate, code) {
        const gs = this.cate[cate];
        if (!gs) {
            this.getStore().query('thirdGames', {
                product_type: 'ALL',
                game_type: cate
            })
        } else {
            const g = gs.find(({tcgGameCode}) => tcgGameCode === code);
            if (g) return g.gameName || code;
        }
        return code;
    }
}
