import {widthStore} from "../../store";
import React, {useEffect, useMemo, useState} from 'react';
import {panel} from "../../components/tabs";
import './t.scss'
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import {change} from "../../components/pwd";
import {fmtErr, numIpt, tip} from "../../utils";
import {dateStr} from "../../components/dateRange";
import {service, Tip} from "../common/com";
import {seqQuery} from "../../api";
import {popSetting} from "./popCenter";
import {popWallet} from "./popWallet";

let stop = 0;
export const Cards = ({banks = [], onChange}) => {
    const [s, ss] = useState(0)
    const [t, tt] = useState(0)
    const [v, vv] = useState(0)
    const l = banks.length;
    const scrollMod = l > 1;
    const bks = useMemo(() => {
        let b = banks.slice();
        if (l > 1) b = b.concat(b).concat(b)
        return b;
    }, [banks, l])
    useEffect(() => {
        if (!t) {
            const a = setTimeout(() => {
                ss(s % l + l)
            }, 30);
            return () => clearTimeout(a);
        }
    }, [t])
    useEffect(() => {
        onChange && onChange(bks[v])
    }, [v, bks])
    const fu = useMemo(() => {
        const b = bks[v];
        if (!b) return null;
        const {bank, account, account_name, bank_id} = b;
        const st = {
            backgroundImage: `url(/banks/${bank}.png)`
        }
        const cs = bank_id === 43 ? ' ali' : '';
        return <div className={'fu' + cs}>
            <i style={st}/>
            <h1>{account}</h1>
            <span>{account_name}</span>
        </div>
    }, [bks, v])
    const tr = {
        transform: `translateX(${(-100 / bks.length * s).toFixed(2)}%)`
    }
    const n = bks.length;
    const mv = i => () => {
        if (stop) return;
        const c = s % n;
        let a = (s + n + (i ? 1 : -1)) % n;
        const next = () => {
            setTimeout(() => {
                stop = 0;
                tt(1);
                ss(a)
            }, 30)
        }
        stop = 1;
        if (i && c >= l * 2) {
            tt(0);
            ss(c - l);
            a = a - l;
            next()
        } else if (!i && c < l) {
            tt(0);
            ss(c + l);
            a = a + l;
            next()
        } else {
            next()
        }
    }
    const sb = bks[v] || {}
    return l ? <div className={'cards'}>
        {fu}
        {panel(l, <div className={'sc'}>
            {panel(scrollMod, <i className={'p'} onClick={mv()}><b/></i>)}
            <div className={'ls'}>
                <div className={'bks' + (t ? ' s' : '')} style={tr}>
                    {bks.map((b, i) => {
                        let cls = b.bank === 'usdt' ? 'usdt' : '';
                        if (b.bank_id === 43) cls = ' ali';
                        if (sb.account === b.account) cls += ' act';
                        return <div
                            className={cls}
                            key={i} onClick={() => vv(i)}>{b.account}</div>
                    })
                    }
                </div>
            </div>
            {panel(scrollMod, <i onClick={mv(1)}><b/></i>)}
        </div>)}
    </div> : null
}


const R = ({data, back, amount, bank}) => {
    if (!data) return null;
    const n = new Date()
    return <div className={'tr-result'}>
        <i/>
        <div className={'inf'}>
            <div className={'r'}>
                <div><label>提现金额：</label><span>{amount}</span></div>
                <div><label>交易时间：</label><span>{dateStr(n, ' ' + n.toLocaleTimeString(), '/')}</span></div>
            </div>
            <div className={'r'}>
                <div><label>订单编号：</label><span>{amount}</span></div>
                <div><label>收款方式：</label><span>{bank && bank.name}</span></div>
            </div>

            <div className={'r'}>
                <div><label>提款状态：</label><span>审核中</span></div>
            </div>
        </div>
        <p>您提款中遇到了什么问题？</p>
        <span onClick={() => service.open()}><i/>联系客服</span>
        <div className={'btn'} onClick={back}>继续提款</div>
    </div>
}

export const Trans = widthStore(
    ({
         myBankStore, globalStore: {
            register_at,
            is_valid_phone,
            is_used_usdt,
            usdt_rate,
            usdt_withdraw_fee,
            max_withdraw = 5,
            today_withdraw,
            withdraw_default_max_amount,
            withdraw_default_min_amount,
            usdt_withdraw_default_min_amount,
            usdt_withdraw_default_max_amount,
            available, is_enable_alipay
        }
     }) => {
        const [da, dd] = useState(null);
        const [getBalance] = useQuery('balance'); //获取余额
        const [getCount] = useQuery('withdrawCount'); //获取余额
        const [dLd, sLd] = useState('');
        const {banks = [], usdts} = myBankStore;
        const items = useMemo(() => {
            let bks = banks.slice()
            if (!+is_enable_alipay) bks = bks.filter(a => a.bank_id !== 43);
            bks = bks.concat((usdts || []).map(({id, address}) => ({
                id: 'usdt' + id,
                account: address,
                bank: 'usdt',
                account_name: 'TRC20'
            })));
            return bks
        }, [banks, usdts, is_enable_alipay]);
        const [bk, bbk] = useState(null);
        const [v0, vv0] = useState('');
        const [v1, vv1] = useState('');
        const [e0, ee0] = useState('');
        const [e1, ee1] = useState('');
        const needPhone=0;
        const needTel = !+is_valid_phone;
            // && (new Date(2021, 8, 1).getTime() < new Date(register_at).getTime());
        useEffect(() => {
            getCount();
            getBalance();
        }, [])
        const isUsdt = bk && bk.bank === 'usdt';
        const isAlipay = bk && bk.bank_id === 43;
        const [min, max] =
            +isUsdt ? [usdt_withdraw_default_min_amount, usdt_withdraw_default_max_amount] :
                [withdraw_default_min_amount, withdraw_default_max_amount];

        const left = max_withdraw - today_withdraw;
        const ok = left && !e0 && !e1 && v0 && v1;
        const x = +usdt_rate && (v0 / usdt_rate);
        const rate1 = isUsdt ? x ? <>预计提款<b>{x.toFixed(2)}USDT</b>,</> : '' : null;
        const rate2 = isUsdt ? <div className={'rt0'}>
            参考汇率：
            1USDT = {(+usdt_rate).toFixed(2)}CNY
        </div> : null;
        const ch1 = v => {
            let a = v0;
            if (typeof v === 'string') a = v;
            if (!a) return ee0('请输入提款金额');
            let vv = parseFloat(a);
            let ext = 0;
            if (isUsdt && usdt_rate) {
                vv = vv / usdt_rate
                ext = usdt_withdraw_fee * usdt_rate
            }
            if (vv < min || vv > max) return ee0('请输入正确的金额');
            if ((+a + ext).toFixed(2, 1) > +available) return ee0('主钱包余额不足请充值')
            ee0('')
        };
        const ch2 = (v) => {
            let a = v1;
            if (typeof v === 'string') a = v;
            if (!a) return ee1('资金密码不能为空');
            if (!/^(?=.*\d+)(?=.*[a-zA-Z]+)(?!.*?([a-zA-Z0-9]})\1\1).{6,16}$/g.test(a))
                return ee1('请输入正确的资金密码');
            ee1('')
        }
        return <div className={'trans-page x-sheet'}>
            <div className={'my-card'}>
                <Cards onChange={bbk} banks={items}/>
                <p>{isAlipay ? '支付宝提款时间：早7点-凌晨2点' : '温馨提示：全天24小时出款服务'}，存款需游戏至少1倍流水后，方可提现。</p>
            </div>
            <div className={'ctx'}>
                {needTel?<div className={'tp'}>
                    <p>
                        为了您的资金安全，请先绑定手机。
                        首次绑定，赠送18元彩金。详情请<b onClick={()=>service.open()}>咨询客服</b>。
                    </p>
                    <div className={'btn'} onClick={()=>{
                        popWallet.hide();
                        popSetting.show(3)
                    }}>
                        去绑定
                    </div>
                </div> : <>
                    <div className={'ipt'}>
                        {rate2}
                        <input
                            onBlur={ch1}
                            className={'a'}
                            onChange={change(v => {
                                if (v) v = (+v).toFixed(0);
                                vv0(v);
                                ch1(v)
                            }, numIpt)}
                            value={v0}
                            placeholder={'请输入提款金额'}/>
                        {e0 && <span><i/>{e0}</span>}
                        <p><s/>提现金额需整数，{isUsdt ? rate1 : <>每日最高可提<b>{'' + max_withdraw}次</b></>}
                            单次提现<b>{min}</b>-<b>{max}</b>{isUsdt ? 'USDT' : '元'},提现剩余次数:
                            <b>{left}次</b>；{isUsdt ? <>提款手续费<b>{usdt_withdraw_fee}U</b></> : null}</p>
                    </div>
                    <div className={'ipt'}>
                        <input
                            placeholder={'请输入资金密码'}
                            type={'password'}
                            className={'b'}
                            value={v1}
                            onChange={change(v => {
                                vv1(v);
                                ch2(v)
                            })}
                            onBlur={ch2}
                        />
                        {e1 && <span><i/>{e1}</span>}
                    </div>
                    <div
                        onClick={() => {
                            if (!dLd && ok) {
                                let id = bk.id;
                                const d = {
                                    amount: v0,
                                    fund_password: v1,
                                    id
                                }
                                if (/usdt/i.test(id)) {
                                    d.id = +id.substr(4);
                                    d.is_usdt = 1;
                                }
                                const next = () => {
                                    seqQuery({
                                        process({failed, finished, results, finish, result}) {
                                            sLd([
                                                '正在申请提现', '正在更新余额', '正在更新提款次数', ''
                                            ][finished])
                                            if (failed || finish) sLd('');
                                            if (failed) tip.alert(<Tip.Err>{fmtErr(result)}</Tip.Err>)
                                            if (finish && !failed) {
                                                tip.alert({text: results[0].msg})
                                            }
                                        }
                                    })(
                                        ['drawMoney', d],
                                        ['withdrawCount'],
                                        ['balance']
                                    )
                                }
                                tip.alert({
                                        text: <>确认提款到{isUsdt?'钱包':isAlipay?'支付宝':'银行卡'}<b style={{padding:'0 5px',color:'#36a1ff'}}>{bk.account}</b>吗?</>,
                                        no: '取消',
                                        yes: '确定',
                                        onClose: (a) => {
                                            if(a)next()
                                        }
                                    }
                                );
                            }
                        }}
                        className={'btn' + (ok ? '' : ' dis')}>
                        {dLd ? '正在提交...' : '立即申请'}
                    </div>
                </>}
            </div>
            <Loading act={dLd}>{dLd}</Loading>
            <R data={da} amount={v0} bank={bk} back={() => dd(null)}/>
        </div>
    })
