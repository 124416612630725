import React, {useEffect, useState} from 'react';
import {Portals} from "../portals";
import './p.scss'

export const PopUpWin = ({children, read, cls = '', onShow, onClose, maskCloseAble, closeAble = true}) => {
    const [s, ss] = useState(0);
    const [a, sa] = useState(0);
    useEffect(() => {
        const t = setTimeout(() => sa(s), s ? 100 : 300);
        return () => clearTimeout(t);
    }, [s])
    useEffect(() => {
        read({
            show() {
                onShow && onShow(ss)
                ss(1);
            },
            hide() {
                ss(0);
                onClose && onClose()
            },
        })
    }, [read])
    return <Portals>
        <div className={cls + ' popup-win-msk' + (s ? ' a' : '') + (a ? ' b' : '')}
             onClick={maskCloseAble ? () => {
                 ss(0)
                 onClose && onClose()
             } : null}
        >
            <div className={'popup-win'} onClick={e=>e.stopPropagation()}>
                {children}
                {closeAble ? <div className={'close'} onClick={() => {
                    ss(0)
                    onClose && onClose()
                }}>X</div> : null}
            </div>
        </div>
    </Portals>
}
