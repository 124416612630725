import React, {useEffect, useMemo, useState} from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import './i.scss'
import {trim} from "../../utils";
import {useQuery} from "../../hooks";
import {change} from "../../components/pwd";
import {dateStr, XDate} from "../../components/dateRange";
import {Loading} from "../../components/loading";
import {COMMISSION_TYPES} from "../../api/enum";



const A = widthDashBoard((props, {
    myBankStore: {banks},
    globalStore: {
        login_ip, signin_at, prize_group,
        username, commission_rate = [], nickname, birthday,
        has_fund_password, real_name, is_valid_phone, is_agent
    }
}) => {
    const [com, cLd, data] = useQuery('getPrize');
    const [set, sLd] = useQuery('setUserInfo');
    const fx = (a = {}) => (parseFloat(a).toFixed(2) || '0') + '%';
    useEffect(() => {
        com()
    }, [])
    const [v0, vv0] = useState(nickname)
    const [v1, vv1] = useState('')
    const r = useMemo(() => {
        let m1 = 0, m2 = 0, m3 = 0, m4 = 0, m5 = 0, m6 = 0;
        commission_rate.forEach(({type_id, commission_rate: r}) => {
            switch (+type_id) {
                case  1:
                    m1 = r;
                    break;
                case  COMMISSION_TYPES.sport:
                    m2 = r;
                    break;
                case  COMMISSION_TYPES.live:
                    m3 = r;
                    break;
                case  COMMISSION_TYPES.rng:
                    m4 = r;
                    break;
                case  COMMISSION_TYPES.pvp:
                    m5 = r;
                    break;
                case  COMMISSION_TYPES.fish:
                    m6 = r;
                    break;
                default:
                    break;
            }
        });
        const {lottery = []} = data || {};
        return {
            fish: fx(m6),
            lottery: [(is_agent ? fx(m1) + ' / ' : '') + prize_group, lottery],
            pvp: fx(m5),
            real: fx(m3),
            rng: fx(m4),
            sport: fx(m2)
        }
    }, [data])
    return <div className={'x-sheet cenPage'}>
        <div className={'x-st-h'}>
            <div className="dash-title "><h1>个人资料</h1></div>
        </div>
        <div className={'x-ss'}>
            <div className={'x-tba'}>
                    <div className={'info'}>
                        <div className={'r'}><label>账号：</label>{username}</div>
                        {/*<div className={'r'}><label>注册时间：</label>{regTime}</div>*/}
                        <div className={'r'}><label>昵称：</label><input
                            value={v0}
                            onChange={change(vv0, trim)}
                        /></div>
                        <div className={'r'}><label>生日：</label>
                            <XDate single readOnly={birthday} value={[birthday]} onChange={vv1}/>
                            <p>生日填写后不可再次修改</p></div>
                        <div className={'btn'} onClick={sLd ? null : () => {
                            set({
                                nickname: v0,
                                birthday: dateStr(v1)
                            })
                        }}>{sLd ? '保存中..' : '保存'}</div>
                    </div>
                    <div className={'si'}>
                        <div className={'a'}>返点</div>
                        <div>
                            <div className={'r'}>
                                <span>真人</span>
                                <span>{r.real}</span>
                                <span>彩票</span>
                                <span className={'v'}>{r.lottery[0]}
                                    <div className={'dt'}>{
                                        r.lottery[1].map(({name, commission, prize_group}, i) => <div key={i}>
                                            <label>{name}</label><span>{(is_agent?fx(commission * 100)+' / ':'')}{prize_group}</span>
                                        </div>)
                                    }</div>
                                </span>
                            </div>
                            <div className={'r'}>
                                <span>体育</span>
                                <span>{r.sport}</span>
                                <span>电子</span>
                                <span>{r.rng}</span>
                            </div>
                            <div className={'r'}>
                                <span>棋牌</span>
                                <span>{r.pvp}</span>
                                <span>捕鱼</span>
                                <span>{r.fish}</span>
                            </div>
                        </div>
                    </div>
                    <Loading act={cLd}>正在更新个人信息</Loading>
            </div>
        </div>
    </div>
})

export const Personal = props => <A {...props} cls={'xt'}/>
